import React from "react"

import { Heading1 } from "../components/common"

export default function PageHeaderImage({
  Image,
  title,
  titleLineTwo,
  headingClassName = "",
  headingStyle = {},
  headingLineTwoClassName = "",
  headingLineTwoStyle = {},
}) {
  return (
    <div className="header-image relative m-auto" style={{ maxWidth: 1200 }}>
      <Image />
      <div className="absolute bottom-0 top-0 left-0 right-0 flex items-center">
        <div>
          <Heading1
            className={`text-gray-700 md:text-5xl ${headingClassName}`}
            style={headingStyle}
          >
            {title}
          </Heading1>
          {!!titleLineTwo && (
            <Heading1
              className={`text-gray-700 md:text-5xl ${headingLineTwoClassName}`}
              style={headingLineTwoStyle}
            >
              {titleLineTwo}
            </Heading1>
          )}
        </div>
      </div>
    </div>
  )
}
