import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 screen image dimensions
 width: 2299
 height: 2088
 width to height ratio: 1.1 (width/height)
*/

export const SpacedRepetitionPanelImage = ({ style = {} }) => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "panel-images/SpacedRepetition.png" }) {
        childImageSharp {
          fixed(width: 363, height: 330) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      imageSmall: file(
        relativePath: { eq: "panel-images/SpacedRepetition.png" }
      ) {
        childImageSharp {
          fixed(width: 200, height: 180) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)

  return (
    <>
      <div className="hidden md:inline">
        <Img fixed={data.image.childImageSharp.fixed} />
      </div>
      <div className="md:hidden flex items-center justify-center">
        <Img fixed={data.imageSmall.childImageSharp.fixed} />
      </div>
    </>
  )
}

export default SpacedRepetitionPanelImage
