import React from "react"

import SEO from "../components/Seo"
import PageWrapper from "../components/PageWrapper"
import SpacedRepetition from "../features/SpacedRepetition"

const FeaturesPage = ({ location }) => {
  return (
    <PageWrapper location={location} containMeNot>
      <SEO title="alvyApp - Spaced Repetition" />
      <SpacedRepetition />
    </PageWrapper>
  )
}

export default FeaturesPage
